const MAX_STARS = 5;

export const getStarsShape = (score: number, maxScore = MAX_STARS) =>
  Array.from({ length: maxScore }, (_, i) => {
    if (score >= i + 0.75) return 'full';

    if (score < i + 0.25) return 'empty';

    return 'half';
  });
