import styled, { css } from 'styled-components';

import ImageObjectFit from '~/src/common/components/ImageObjectFit';
import { H3 } from '~/src/common/components/Typography';
import { transparentize } from '~/src/common/utils/style';

const PICKER_SECTION_HEIGHT = 56;

export const CARD_RATIO = 0.625;

export const productCardTagStyle = css`
  position: absolute;
  z-index: 1;
  top: 8px;
  left: 8px;

  padding: 0 4px;

  border-radius: 4px;

  font-size: 12px;
  line-height: 24px;
  font-weight: 500;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Container = styled.article`
  position: relative;
  overflow: hidden;

  aspect-ratio: ${CARD_RATIO};

  width: 100%;
  height: 100%;

  border-radius: 8px;
  background: ${({ theme }) => theme.palette.common.PLACEHOLDER};
  box-shadow: ${({ theme }) => transparentize(theme.palette.common.BLACK_PURE, 0.15)} 0 0 8px 0;

  & > a {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

export const Content = styled.div`
  position: absolute;
  top: initial;
  bottom: 0;
  width: 100%;
  height: 100%;
`;

export const TopSectionContainer = styled.div`
  height: calc(calc(100% - ${PICKER_SECTION_HEIGHT}px) + 0.5px);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const Image = styled(ImageObjectFit)`
  position: absolute;

  /* Masque un effet sur les bords de l'image */
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
`;

export const InformationContainer = styled.div`
  padding: 48px 8px 8px;
  background: ${({ theme }) =>
    `linear-gradient(${theme.palette.common.TRANSPARENT}, ${transparentize(
      theme.palette.common.BLACK_PURE,
      0.8,
    )})`};
`;

export const ProductNameContainer = styled.div`
  margin-bottom: 8px;
`;

export const ProductName = styled(H3)`
  color: ${({ theme }) => theme.palette.common.WHITE};
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  word-break: break-word;
  margin: 0;
`;

export const BottomSectionContainer = styled.div`
  position: relative;
  min-height: ${PICKER_SECTION_HEIGHT}px;
  max-height: ${PICKER_SECTION_HEIGHT}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background-color: ${({ theme }) => theme.palette.common.WHITE};
`;
