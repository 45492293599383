import styled from 'styled-components';

import { BookmarkProductButton as BookmarkProductButtonBase } from '~/src/common/components/BookmarkProductButton';

export const BookmarkProductButton = styled(BookmarkProductButtonBase)`
  position: absolute;
  z-index: 1;
  top: 8px;
  right: 8px;

  border-radius: 4px;

  && {
    background-color: ${({ theme }) => theme.palette.common.WHITE};
  }
`;
