import React, { SyntheticEvent } from 'react';

import Link from '~/src/common/components/Link';
import Rating from '~/src/common/components/Rating';
import I18n from '~/src/common/services/I18n';
import { useNiceModal } from '~/src/common/services/ModalsManager';
import Tracker, {
  AddToCartProductEventSource,
  getBookmarkEventMapping,
} from '~/src/common/services/Tracker';
import { ImageFormatIds, ImageParams } from '~/src/common/typings/image';
import { getImageUrlFromFormat } from '~/src/common/utils/cloudinary';
import { getRestockDate } from '~/src/common/utils/date';
import { useProductLink } from '~/src/screens/CatalogContent/ContentScreen/hooks';
import { CartRelatedProduct, Product, RelatedProduct } from '~/src/typings/products/types';

import * as GS from '../../layout';
import * as CS from '../layout';

import * as S from './layout';

interface Props {
  product: Product | RelatedProduct | CartRelatedProduct;
  categoryName?: string;
  subcategoryName?: string;
  eventSource: AddToCartProductEventSource;
}

const UnavailableProductCardInner = ({
  product,
  eventSource,
  categoryName,
  subcategoryName,
}: Props) => {
  const { id, name, shortage, restockDate, hasSubstituteProducts, images, rating } = product;

  const imageUrl = getImageUrlFromFormat(
    images[0],
    ImageFormatIds.CardRatio,
    ImageParams.listingCard,
  );

  const { as, query } = useProductLink(product.slug, {
    category: categoryName,
    subcategory: subcategoryName,
    eventSource,
  });

  const unavailableLabel = restockDate
    ? `${I18n.t('product-card.back-on')} ${getRestockDate(
        restockDate.date,
        restockDate.displayHour,
      )}`
    : shortage;

  const { show: showSubstituteProductsModal } = useNiceModal('substitute-products-modal');

  const handleSubstituteProductsClick = (e?: SyntheticEvent) => {
    if (e != null) {
      e.stopPropagation();
      e.preventDefault();
    }

    Tracker.sendEvent('click cta similar products', {
      'product event source': eventSource,
      'product name': name,
      'product id': id,
      'product category': categoryName,
      'product subcategory': subcategoryName,
    });

    // @ts-expect-error -- Typage à revoir sur la modale de gestion des indispos
    // eslint-disable-next-line @typescript-eslint/no-floating-promises  -- auto-ignored when updating eslint
    showSubstituteProductsModal({ product });
  };

  const bookmarkEventProperties = getBookmarkEventMapping({
    product,
    productEventSource: eventSource,
    categoryName,
    subcategoryName,
  });

  return (
    <Link href={{ pathname: as, query }} as={as}>
      <S.Image src={imageUrl} $objectFit="cover" $objectPosition="top center" alt={product.name} />

      <S.UnavailableTag>{I18n.t('product-card.unavailable')}</S.UnavailableTag>

      <CS.BookmarkProductButton
        product={product}
        bookmarkEventProperties={bookmarkEventProperties}
      />

      <GS.Content>
        <GS.TopSectionContainer>
          <GS.InformationContainer>
            <GS.ProductNameContainer>
              <GS.ProductName>{name}</GS.ProductName>
              <Rating rating={rating} />
            </GS.ProductNameContainer>
            <S.RestockInformation>{unavailableLabel}</S.RestockInformation>
          </GS.InformationContainer>
        </GS.TopSectionContainer>

        <GS.BottomSectionContainer>
          {hasSubstituteProducts ? (
            <S.SubstituteProductsContainer role="button" onClick={handleSubstituteProductsClick}>
              <label htmlFor={`substitutes-products-${id}`}>
                {I18n.t('common.see-substitutes-products')}
              </label>
              <S.SubstituteProductButton
                variant="secondary"
                icon="Replace"
                iconSize={23}
                id={`substitutes-products-${id}`}
                onClick={handleSubstituteProductsClick}
              />
            </S.SubstituteProductsContainer>
          ) : (
            <S.NoSubstituteProduct>
              {I18n.t('product-card.no-substitute-product')}
            </S.NoSubstituteProduct>
          )}
        </GS.BottomSectionContainer>
      </GS.Content>
    </Link>
  );
};

export default React.memo(UnavailableProductCardInner);
