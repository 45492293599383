import styled from 'styled-components';

export const RatingContainer = styled.div`
  display: flex;
  height: 16px;

  p {
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.palette.common.WHITE};
    line-height: 16px;
    margin: 0;
    margin-left: 4px;
  }
`;

export const StarsContainer = styled.div`
  line-height: 15px;
`;
