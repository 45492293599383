export default [
  'France',
  'Martinique',
  'Guadeloupe',
  'Réunion',
  'France Réunion',
  'France (La Réunion)',
  'Guyane',
  'Mayotte',
  'Saint-Pierre-et-Miquelon',
  'Saint-Barthélemy',
  'Saint-Martin',
  'Wallis-et-Futuna',
  'Polynésie française',
  'Nouvelle-Calédonie',
];
